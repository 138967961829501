import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Article = styled.article`
  padding: 0 0 56px;

  a {
    color: #006fc6;
  }

  h1 {
    display: block;
    font-size: 32px;
    font-weight: normal;
    line-height: 38px;
  }

  p {
    font-size: 32px;
    font-weight: normal;
    line-height: 38px;
    padding-top: 62px;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 62px;
      line-height: 72px;
      margin: 0 auto 0;
      width: 65%;
    }

    p {
      font-size: 42px;
      line-height: 50px;
      margin: 0 auto 0;
      width: 65%;
    }
  }

  @media (min-width: 992px) {
    h1 {
      width: 50%;
    }

    p {
      width: 50%;
    }
  }
`

const HeaderImg = styled(Img)`
  width: 100%;
  margin-bottom: 76px;

  @media (min-width: 768px) {
    display: block;
    margin: 0 auto 76px;
    width: 65%;
  }

  @media (min-width: 992px) {
    width: 50%;
  }
`

const Contact = ({ data }) => (
  <Layout>
    <SEO title="Contact" />
    <Article>
      <HeaderImg
        fluid={data.proFBBHelleTrevinoImage.childImageSharp.fluid}
        alt="professional female bodybuilder Helle Trevino"
      />
      <h1>Contact</h1>
      <p>Business: <br />helleifbb@gmail.com</p>
      <p>
        Fans: <br />Please direct your fan mail to{" "}
        <a
          href="https://www.instagram.com/helletrevino/"
          rel="noreferrer"
          target="_blank"
        >
          Instagram direct messaging
        </a>
      </p>
    </Article>
  </Layout>
)

export default Contact

export const query = graphql`
  query ContactDataQuery {
    proFBBHelleTrevinoImage: file(
      relativePath: { eq: "professional-female-bodybuilder-helle-trevino.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
